import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import Header from "../components/Layout/Header"
import FooterDisplayBlok from "../components/CommonComponents/FooterDisPlay"

const Content = ()=>(
  <>
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={"Personal Online Office - Cubo"}
    >
      <meta name="robots" content="noindex" />
    </Helmet>
    <section className="notfound-section">
      <div className="notfound-wrapper">
        <img loading="lazy" src={"/assets/images/not-found.png"} alt="404" />
        <p className="notfound-kindtips">
          The page you are looking for can’t be found.
        </p>
        {/* <p className="reminder">Redirecting to home page in {time}s ...</p> */}

        <Link to="/" className="mt-5">
          <Button variant="success" className="primary-btn simple-btn">
            BACK TO HOME
          </Button>
        </Link>
      </div>
    </section>
  </>
)
const Page404 = () => {
  return (
    <>
      <Header />
      <Content />
      <FooterDisplayBlok />
    </>
  )
}

export default Page404
